<template>
  <div>
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header text-center">
              <b>{{ result.test.name }}</b>
            </div>
            <div class="card-header text-center text-white" :class="percentageScore > 49 ? 'bg-success' : 'bg-danger'">
              <b>Results {{ percentageScore }}%</b>
            </div>
            <div class="card-body" style="min-height: 150px">
              <div class="row">
                <div class="col-md-8 offset-2">
                  <table class="table table-striped table-sm">
                    <tr>
                      <td class="text-right">Score:</td>
                      <th class="text-left">{{ result.score }} / {{ result.totalQuestionsGiven }}</th>
                    </tr>
                    <!-- <tr>
                      <td class="text-right">Percentage Score:</td>
                      <th class="text-left">{{ percentageScore }}%</th>
                    </tr> -->
                    <tr>
                      <td class="text-right">Questions Given:</td>
                      <th class="text-left">{{ result.totalQuestionsGiven }}</th>
                    </tr>
                    <tr>
                      <td class="text-right">Questions Answered:</td>
                      <th class="text-left">{{ result.totalQuestionsAnswered }}</th>
                    </tr>
                    <tr>
                      <td class="text-right">Minutes Given:</td>
                      <th class="text-left">{{ result.totalMinutesGiven }} minutes</th>
                    </tr>
                    <tr>
                      <td class="text-right">Minutes Used:</td>
                      <th class="text-left">{{ result.totalMinutesUsed }} minutes</th>
                    </tr>
                    <tr>
                      <td class="text-right">Date & Time:</td>
                      <td class="text-left">
                        <small>{{ result.created_at | formatDateTime }}</small>
                      </td>
                    </tr>
                    <tr></tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <h1 class="mt-5 mb-4">Reviews <small>attempt</small></h1>
          <div class="accordion" role="tablist">
            <b-card no-body class="mb-1" v-for="(script, index) in scripts" :key="index">
              <b-card-header header-tag="header" class="p-1" role="tab">
                <!-- <b-button block  variant="info">Question {{ index + 1 }}</b-button> -->
                <button class="btn btn-block btn-sm btn-secondary" v-b-toggle="'accordion-' + index">Question {{ index + 1 }}</button>
              </b-card-header>
              <b-collapse :id="'accordion-' + index" visible accordion="my-accordion" role="tabpanel">
                <b-card-body>
                  <table class="table table-sm table-striped table-hover resultTable" style="font-size: 13px">
                    <tr>
                      <th class="text-right">Question Given</th>
                      <td><div v-html="script.question.content"></div></td>
                    </tr>
                    <tr>
                      <th class="text-right">Question Feedback</th>
                      <td><div v-html="script.question.feedback"></div></td>
                    </tr>

                    <!-- Answer Given -->
                    <tr>
                      <th class="text-right">Answer Chosen</th>
                      <td><div v-html="script.answer.content"></div></td>
                    </tr>
                    <tr>
                      <th class="text-right">Answer Feedback</th>
                      <td><div v-html="script.answer.feedback"></div></td>
                    </tr>

                    <!-- Score -->
                    <tr>
                      <th class="text-right">Score</th>
                      <td>
                        {{ script.score }}
                        <i class="icon icon-check text-success ml-4" v-if="script.score == 1"></i>
                        <i class="icon icon-times text-danger ml-4" v-else></i>
                      </td>
                    </tr>
                  </table>
                </b-card-body>
              </b-collapse>
            </b-card>

            <!-- <b-card no-body class="mb-1">
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button block v-b-toggle.accordion-2 variant="info">Accordion 2</b-button>
              </b-card-header>
              <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
                <b-card-body> dddddddddd dddd </b-card-body>
              </b-collapse>
            </b-card> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "PractiseTestResult",

  data() {
    return {
      result: { test: { name: "" } },
      scripts: [],
      percentageScore: 0,
    };
  },

  mounted() {
    axios.get(`/candidate/tests/result/${this.attemptUUID}`).then((res) => {
      this.result = res.data.result;
      this.scripts = res.data.scripts;
      this.percentageScore = (res.data.result.score / res.data.result.totalQuestionsGiven) * 100;
    });
  },

  computed: {
    attemptUUID() {
      return this.$route.params.attemptUUID;
    },
  },
};
</script>




