import Vue from 'vue'
import VueRouter from 'vue-router'
import auth from '../helper/auth'
// page
import Login from '../views/Login.vue'
import PageNotFound from '../views/PageNotFound.vue'
import Home from '../views/Home.vue'
import TestDetails from '../views/TestDetails.vue'
import PractiseTestRender from '../views/PractiseTestRender.vue'
import PractiseTestResult from '../views/PractiseTestResult.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    beforeEnter: auth.ifNotAuthenticated,
    component: Login
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    beforeEnter: auth.ifAuthenticated,
  },
  {
    path: '/test/:uuid',
    name: 'TestDetails',
    component: TestDetails,
    beforeEnter: auth.ifAuthenticated,
  },
  {
    path: '/practis-test/:attemptUUID',
    name: 'PractiseTestRender',
    component: PractiseTestRender,
    beforeEnter: auth.ifAuthenticated,
  },
  {
    path: '/practis-test-result/:attemptUUID',
    name: 'PractiseTestResult',
    component: PractiseTestResult,
    beforeEnter: auth.ifAuthenticated,
  },
  {
    path: '/*',
    name: 'PageNotFound',
    component: PageNotFound
  },
]

const router = new VueRouter({
  mode: 'history',
  // base: process.env.BASE_URL,
  routes
})

export default router
