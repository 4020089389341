<template>
  <div id="app">
    <b-navbar toggleable="lg" type="light" variant="light" fixed="top">
      <router-link to="/" target="_self" class="navbar-brand">
        <img src="./assets/logo.png" alt="" height="25" />
      </router-link>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <!-- <b-navbar-nav>
           <b-nav-item href="#">Link</b-nav-item>
          <b-nav-item href="#" disabled>Disabled</b-nav-item> 
        </b-navbar-nav>-->

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <li class="nav-item" v-show="!loggedIn"><router-link to="/login" target="_self" class="nav-link"> Login </router-link></li>
          <b-nav-item-dropdown right v-show="loggedIn">
            <!-- Using 'button-content' slot -->
            <template #button-content>
              <img :src="authUser.photo" :alt="authUser.name" style="height: 30px; border-radius: 100px" />
            </template>
            <div id="userAccount">
              <p>
                <img :src="authUser.photo" :alt="authUser.name" style="height: 150px; border-radius: 100px" />
              </p>
              <h6>{{ authUser.name }}</h6>
              <span style="font-size: 11px">{{ authUser.email }}</span>
            </div>
            <!-- <b-dropdown-item href="#">Profile</b-dropdown-item> -->
            <div class="dropdown-divider"></div>
            <router-link class="dropdown-item" to="/">My Assessment</router-link>
            <div class="dropdown-divider"></div>
            <b-dropdown-item href="#" @click="logout">Sign Out</b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <router-view />
    <vue-progress-bar></vue-progress-bar>
  </div>
</template>


<script>
import axios from "axios";

export default {
  mounted() {
    //  [App.vue specific] When App.vue is finish loading finish the progress bar
    this.$Progress.finish();
  },

  created() {
    //  [App.vue specific] When App.vue is first loaded start the progress bar
    this.$Progress.start();
    //  hook the progress bar to start before we move router-view
    this.$router.beforeEach((to, from, next) => {
      //  does the page we want to go to have a meta.progress object
      if (to.meta.progress !== undefined) {
        let meta = to.meta.progress;
        // parse meta tags
        this.$Progress.parseMeta(meta);
      }
      //  start the progress bar
      this.$Progress.start();
      //  continue to next page
      next();
    });
    //  hook the progress bar to finish after we've finished moving router-view
    this.$router.afterEach((to, from) => {
      //  finish the progress bar
      this.$Progress.finish();
    });
  },

  computed: {
    authUser() {
      return this.$store.state.authUser;
    },
    loggedIn() {
      return this.$store.state.loggedIn;
    },
  },

  methods: {
    logout() {
      axios.post("/auth/logout").then((res) => {
        if (res.data.status == "success") {
          this.$store.state.authUser = {};
          this.$store.state.authToken = "";
          this.$store.state.loggedIn = false;
          window.localStorage.removeItem("log_uuid");
          axios.defaults.headers.common["Authorization"] = "Bearer " + process.env.VUE_APP_API_KEY;
          this.$swal("", "Logged out successfully.", "info");
          this.$router.push("/login");
        }
      });
    },
  },
};
</script>


<style>
body {
  padding: 70px 0;
}
#userAccount {
  width: 100%;
  text-align: center;
  padding: 20px;
}

.navbar {
  border-bottom: 1px solid #eee;
}
</style>
