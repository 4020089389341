<template>
  <div>
    <div class="container">
      <div class="row">
        <div class="col-md-12 mt-5 mb-5">
          <h1>My Assessments</h1>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <b-card>
            <b-card-body>
              <vue-good-table :columns="columns" :rows="tests" :search-options="searchOptions" :pagination-options="paginationOptions">
                <template slot="table-row" slot-scope="props">
                  <span v-if="props.column.field == 'Name'">
                    <router-link :to="{ name: 'TestDetails', params: { uuid: props.row.UUID } }">
                      <b>{{ props.row.Name }}</b>
                    </router-link>
                  </span>
                  <span v-if="props.column.field == 'NoOfQuestionsToRender'">{{ props.row.NoOfQuestionsToRender }}</span>
                  <span v-if="props.column.field == 'TimeSet'">{{ props.row.TimeSet }}minutes</span>
                  <span v-if="props.column.field == 'TotalAttempt'">{{ props.row.TotalAttempt }}</span>
                </template>
              </vue-good-table>
            </b-card-body>
          </b-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "Home",

  data() {
    return {
      tests: [],
    };
  },

  mounted() {
    axios.get("/candidate/tests").then((res) => {
      this.tests = res.data.tests;
    });
  },

  computed: {
    columns() {
      let cols = [
        {
          label: "Name",
          field: "Name",
        },
        {
          label: "Questions",
          field: "NoOfQuestionsToRender",
        },
        {
          label: "Time",
          field: "TimeSet",
        },
        {
          label: "Attempts",
          field: "TotalAttempt",
        },
      ];
      return cols;
    },

    searchOptions() {
      return {
        enabled: true,
        trigger: "enter",
      };
    },

    paginationOptions() {
      return {
        enabled: true,
        perPage: 20,
      };
    },
  },

  methods: {},
};
</script>
