<template>
  <div>
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <h2 class="mt-5 mb-3">{{ test.name }}</h2>
          <div class="card">
            <!-- <div class="card-header">Featured</div> -->
            <div class="card-body">
              <!--  -->
              <h5 class="card-title">Introduction</h5>
              <div class="card-text">
                <p>{{ test.introduction }}</p>
              </div>
              <!--  -->
              <h5 class="card-title">Description</h5>
              <div class="card-text" v-html="test.description"></div>
              <!--  -->
              <h5 class="card-title">Instructions</h5>
              <div class="card-text" v-html="test.prerequisite"></div>
              <!--  -->
            </div>
            <div class="card-footer">
              <button class="btn btn-danger" @click="start(test.uuid)">Start new attempt</button>
            </div>
          </div>
        </div>
      </div>

      <div class="row" v-if="attempts.length > 0">
        <div class="col-md-12">
          <h2 class="mt-5 mb-4">Previous <small>Attempt</small></h2>
          <!-- Previous Attempt -->
          <div class="card">
            <div class="card-body">
              <table class="table table-striped" style="font-size: 13px">
                <tr>
                  <!-- <th>SN</th> -->
                  <th>Date/Time</th>
                  <th>Key</th>
                  <th>Questions</th>
                  <th>Answered</th>
                  <th>Minutes set</th>
                  <th>Minutes used</th>
                  <th>Score</th>
                </tr>
                <tr v-for="(attempt, index) in attempts" :key="index">
                  <!-- <td>{{ index + 1 }}</td> -->
                  <td class="text-left">{{ attempt.created_at | formatDateTime }}</td>
                  <td class="text-left">
                    <router-link :to="{ name: 'PractiseTestResult', params: { attemptUUID: attempt.uuid } }">
                      {{ attempt.uuid }}
                    </router-link>
                  </td>
                  <td class="text-center">{{ attempt.totalQuestionsGiven }}</td>
                  <td class="text-center">{{ attempt.totalQuestionsAnswered }}</td>
                  <td class="text-center">{{ attempt.totalMinutesGiven }}</td>
                  <td class="text-center">{{ attempt.totalMinutesUsed }}</td>
                  <td class="text-center">{{ attempt.score }}</td>
                </tr>
              </table>
            </div>
          </div>
          <!-- Previous Attempt -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "TestDetails",
  data() {
    return {
      test: {},
      attempts: {},
    };
  },

  mounted() {
    axios.get(`/candidate/tests/${this.uuid}`).then((res) => {
      this.test = res.data.test;
      this.attempts = res.data.attempts;
    });
  },

  methods: {
    start(uuid) {
      axios.get(`/candidate/tests/start/${this.uuid}`).then((res) => {
        if (res.data.status == "success") {
          this.$router.push({ name: "PractiseTestRender", params: { attemptUUID: res.data.attemptUUID } });
        }
      });
    },
  },

  computed: {
    uuid() {
      return this.$route.params.uuid;
    },
  },
};
</script>
